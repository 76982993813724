import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "my-lists"
    }}>{`My Lists`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Patrons can create, manage, update and delete lists for catalog items or events. Selecting 'My Lists' from the My Account menu option will load the lists display. `}</li>
      <li parentName="ol">{`Any event that has been favorited or any created book lists will be shown here. Patrons can swipe through the items in each list by scrolling horizontally. `}<em parentName="li">{`Events will need to be configured in order for patrons to favorite them.`}</em></li>
      <li parentName="ol">{`Book lists that have several items in it, can be expanded to show the full list of items by clicking 'More'. Selecing any title from the list will load the Iteam Details Display. `}<em parentName="li">{`Only lists with at least one item in it have the 'More' button.`}</em></li>
      <li parentName="ol">{`Selecting 'Edit' will allow the patron to either rename or delete the list. If the patron is renaming the list, they must provide a name that is longer than 3 characters.`}</li>
    </ol>
    <p><img alt="My Lists Homescreen" src={require("./images/my_lists_homescreen.png")} />{` `}<img alt="More My Lists Screen" src={require("./images/my_lists_more_list.png")} />{` `}<img alt="Edit My Lists Screen" src={require("./images/my_lists_edit_list.png")} /></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Patrons can add a catalog item to their list by searching for an item, opening the Item Details Display and clicking the bookmark icon found in the top right corner. `}</li>
      <li parentName="ol">{`When adding a catalog item to a list, patrons can select from either an already created list by clicking on the bookmark icon next to the list name or by creating a new list. `}</li>
      <li parentName="ol">{`Creating a new list will require the patron to select 'New List' and provide a name for the list. Selecting 'New List' will create the list.`}</li>
      <li parentName="ol">{`Selecting 'Done' will add the item to the list. In the top right corner, the bookmark icon has been filled in, visualizing that this item is currently in a list.`}</li>
    </ol>
    <p><img alt="Add Item to My Lists Screen" src={require("./images/my_lists_add_item.png")} />{` `}<img alt="Create Lists Screen" src={require("./images/my_lists_create_new.png")} />{` `}<img alt="Create Lists Screen" src={require("./images/my_lists_create_new2.png")} />{` `}<img alt="Added Item to My Lists Screen" src={require("./images/my_lists_added_icon.png")} />{` `}<img alt="My Lists Homescreen Dys" src={require("./images/my_lists_dys_homescreen.png")} /></p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Patrons can favorite an event by navigating to the event and clicking the heart icon in the top right corner. `}</li>
      <li parentName="ol">{`Once an event has been favorited, it will display in the the My Lists section under Favourite Events. `}</li>
    </ol>
    <p><img alt="View Event Screen" src={require("./images/my_lists_favorite_event.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      